import React from "react";
import SectionWrapper from "../UI/SectionWrapper";
import Carousel from "../UI/Carousel";
import BackgroundForSliderTest from '../../assets/img/background-for-banner-slider.jpg';
import BannerThird from '../../assets/img/banner-third.png';


const slides = [
    {
        image: BackgroundForSliderTest,
        title: "Ручна робота надає предметам особливого характеру.",
        // description: "Несемо особисту відповідальність за якість і результат роботи.",

    },
    {
        image: 'https://i.imgur.com/Vlasax3.jpg',
        // title: "Наші технології та досвід роблять будь-який проект успішним: це якісно, ​​вчасно, в обумовленому бюджеті.",
        title: "Ми співпрацюємо з кожним клієнтом індивідуально, враховуючи його потреби та бюджет.",
    },
    {
        image: BannerThird,
        // title: "Ідеї ​​– це капітали, ми помножимо ваші статки, перетворивши ідеї на життя.\n",
        title: "Ваша мрія – наша реалізація.",
    }
];

function Banner() {
    return (
        <SectionWrapper id="banner" paddingTop={0}>
            <Carousel slides={slides}/>
        </SectionWrapper>
    );
}

export default Banner;






