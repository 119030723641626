import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import SectionWrapper from "../UI/SectionWrapper";
import {Box} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Banner from '../../assets/img/banner-for-about.png';
import Logo from '../../assets/icon/logo.svg';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'stretch',
        gap: '20px',
    },
    boxView: {
        flex: '0 0 30%',
        backgroundColor: 'transparent',
        borderRadius: '12px',
        border: '2px solid #fff',
        padding: '24px 12px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
        [theme.breakpoints.down('sm')]: {
            flex: '0 0 100%',
        }
    },
    textBlock: {
        fontFamily: 'Inter-Regular',
        fontSize: '16px',
        color: '#F2F2F2',
        lineHeight: '20px',
        '& span': {
            color: '#BDFFA6',
            fontWeight: 'bold',
        }
    },
    imgContainer: {
        flex: '1',
        backgroundImage: `url(${Banner})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: '12px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },
    logo: {
        width: '100px',
        height: '100px',
    }
}));

const textAbout = [
    {
        title: 'Wood +',
        text: ' - Ми існуємо на ринку з 2011 року. Для нас це не лише наша робота це справа усього нашого життя. Кожен замовник для нас стає новим другом та частиною нашої компанії. Ми почали з самого нуля, не маючи нічого, ми набували досвіду, розвивалися, до кожного замовлення ми відносилися з неабияким інтузиазмом та відповідальністю. Для нас це було та є чимось особливим. Ми не боялися труднощів тому на сьогоднішній день ми маємо великий колектив, працюємо у декількох містах України та несемо відповідальність за свою роботу. Для нас важливо, щоб наші вироби повністю задовольняли ваші найвибагливіші вимоги. Власне виробництво виробів з металу та дерева дозволяють створювати ексклюзивні моделі для найвибагливіших Замовників.',
    }
]

const About = () => {
    const classes = useStyles();

    return (
        <SectionWrapper id="about">
            <Box className={classes.container}>
                <Box className={classes.boxView}>
                    <img src={Logo} alt="logo" className={classes.logo}/>
                    <Typography className={classes.textBlock}>
                        <span>{textAbout[0].title}</span> {textAbout[0].text}
                    </Typography>
                </Box>
                <Box className={classes.imgContainer}/>
            </Box>
        </SectionWrapper>
    );
};

export default About;



