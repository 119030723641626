import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import SectionWrapper from "../UI/SectionWrapper";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Box,
    Divider
} from "@material-ui/core";
import {Remove as RemoveIcon, Add as AddIcon} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    accordion: {
        marginBottom: '0',
        backgroundColor: "transparent",
        boxShadow: "none",
        "&:before": {
            display: "none"
        },
        "&$expanded": {
            margin: "auto",
        }
    },
    accordionSummary: {
        justifyContent: "space-between",
        padding: '8px 16px 8px 0',
        alignItems: "center",

        "& .MuiIconButton-root": {
            padding: theme.spacing(1),
            marginLeft: theme.spacing(1),
            backgroundColor: "white",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        "& .MuiIconButton-root:first-child": {
            marginLeft: 0,
        },
        "&.Mui-expanded .MuiIconButton-root": {
            backgroundColor: "#BDFFA6",
        },
        '& .svg.MuiSvgIcon-root': {
            width: '1em',
            height: '1em',
        }
    },
    accordionDetails: {
        flexDirection: "column",
        padding: '0',
    },
    divider: {
        marginTop: '0',
        backgroundColor: '#F2F2F2'
    },
    title: {
        fontSize: '48px',
        fontWeight: 'bold',
        color: '#F2F2F2',
        fontFamily: 'Inter-Bold',
        textAlign: 'center',
        marginBottom: '32px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px',
        }
    },
    reasonTitle: {
        fontFamily: 'Inter-Regular',
        fontWeight: 600,
        fontSize: '20px',
        color: '#F2F2F2',
        lineHeight: '24px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        }
    },
    description: {
        fontFamily: 'Inter-Regular',
        fontWeight: 400,
        fontSize: '20px',
        color: '#F2F2F2',
        lineHeight: '24px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        }
    },
    boxView: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end'
    },
    accordionBox: {
        width: '68%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    }
}));

const Reasons = () => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(null);

    const handleAccordionChange = (index) => {
        setExpanded(index === expanded ? null : index);
    };

    const servicesData = [
        {
            title: "Розміри та форма",
            description: "Ми займаємося виготовленням товарів на замовлення, тому зможемо внести зміни в будь-який вибраний вами виріб: форма, розмір, покриття, колір, порода дерева та інше. Зв'яжіться з нами і ми зробимо все як треба!\n"
        },
        {
            title: "Матеріали",
            description: "Переважна більшість наших виробів виготовляється зі сосни. Сосна – міцний і екологічний матеріал, з якого виготовляється дошка обрізна, дошка необрізна. Вона може використовуватися в будівельних і оздоблювальних роботах: як елемент підлог, перекриттів; у виробництві вікон, сходів, дверей; при покрівельних роботах; для проведення оздоблювальних робіт; у меблевому виробництві. Вся продукція відповідає ГОСТ. Саме державний стандарт ділить їх на різні сорти, які строго відповідають розмірам і вологості, передбачених у державному стандарті. Але за індивідуальним бажанням замовника є можливість використання й інших видів дерева."
        },
        {
            title: "Терміни виконання",
            description: "Ми є виробниками. У зв’язку з великою кількістю замовлень, Вашого товару може не виявитися в наявності. Термін виконання замовлення залежить від ступеня завантаженості виробництва і поточної кількості замовлень, тому може коливатися від одного дня до 1 місяця. Однак ми постараємося виконати Ваше замовлення в максимально стислі терміни!" + "\n" + "Якщо замовлений товар є в наявності, то він буде відправлений у перший же робочий день після його замовлення. Перед відправкою ми обов’язково зв’яжемося з Вами."
        },
        {
            title: "Способи доставки",
            description: "Доставка здійснюється тільки по передоплаті. <ul><li> Самовивіз Безкоштовно.</li><li>Доставка проводиться транспортом нашої компанії, вартість необхідно уточнювати.</li><li>Доставка \"Meest ПОШТА\" .</li><li>Делівер. Відправлення здійснюється після 100% оплати замовлення.</li><li>Доставка \"Нова Пошта\"Відправлення здійснюється після 100% оплати замовлення, або 70% передоплати, якщо Ви хочете скористатися послугою післяплати.</li><li>* При отриманні замовлення перевіряйте... </li></ul>"
        },
        {
            title: "Способи оплати",
            description: "<ul><li>Оплата за реквізитами.</li><li>Оплата на карту Приват Банку.</li><li>Оплата банківською карткою.</li><li>Готівковий розрахунок.</li><li>Також є можливість розтермінування від виробника.</li></ul>"
        }
    ];
    return (
        <SectionWrapper id="reasons">
            <Box>
                <Typography className={classes.title}>Як ми працюємо</Typography>
            </Box>
            <Box className={classes.boxView}>
                {servicesData.map((service, index) => (
                    <React.Fragment key={index}>
                        <Box className={classes.accordionBox}>
                            <Accordion
                                key={index}
                                className={classes.accordion}
                                expanded={index === expanded}
                                onChange={() => handleAccordionChange(index)}
                            >
                                <AccordionSummary
                                    expandIcon={index === expanded ? <RemoveIcon/> : <AddIcon/>}
                                    className={classes.accordionSummary}
                                >
                                    <Typography className={classes.reasonTitle}>{service.title}</Typography>
                                </AccordionSummary>
                                <AccordionDetails className={classes.accordionDetails}>
                                    <Typography className={classes.description}
                                                dangerouslySetInnerHTML={{
                                                    __html: service.description
                                                }}
                                    />
                                </AccordionDetails>
                            </Accordion>
                            <Divider className={classes.divider}/>
                        </Box>
                    </React.Fragment>
                ))}
            </Box>
        </SectionWrapper>
    );
};

export default Reasons;



