import React, {useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/swiper.min.css";
import SwiperCore, {Pagination, Autoplay, EffectFade} from "swiper";
import {makeStyles} from "@material-ui/core/styles";
import CustomButton from "../Button";
import Typography from "@material-ui/core/Typography";

SwiperCore.use([Pagination, Autoplay, EffectFade]);

const useStyles = makeStyles((theme) => ({
    slider: {
        width: '100%',
        height: '80vh',
        position: 'relative',
    },
    slide: {
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: '30px',
    },
    slideContent: {
        position: 'absolute',
        top: '60%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        color: '#fff',
        zIndex: 1,
        [theme.breakpoints.down('sm')]: {
            top: '50%',
            width: '80%',
        }
    },
    pagination: {
        position: "absolute",
        bottom: 30,
        left: "50%",
        transform: "translateX(-50%)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1,
        clickable: false,
    },
    paginationItem: {
        width: 100,
        height: 5,
        background: "#fff",
        margin: "0 10px",
        opacity: 0.5,
        clickable: false,
        transition: "opacity 0.3s ease",
        [theme.breakpoints.down('sm')]: {
            width: 50,
            height: 3,
        }
    },
    paginationItemActive: {
        opacity: 1,
    },
    title: {
        fontSize: '40px',
        fontWeight: 'bold',
        marginBottom: '15px',
        lineHeight: '48px',
        letterSpacing: '3%',
        textAlign: 'center',
        color: '#fff',
        fontFamily: 'Inter-Bold',
        [theme.breakpoints.down('sm')]: {
            fontSize: '22px',
        }
    },
    description: {
        fontFamily: 'Inter-Regular',
        fontSize: '20px',
        fontWeight: 400,
        lineHeight: '24px',
        letterSpacing: '0.03em',
        marginBottom: '40px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        }
    }
}));

const Carousel = ({slides}) => {
    const classes = useStyles();
    const [activeSlide, setActiveSlide] = useState(0);

    const handleSlideChange = (swiper) => {
        const {activeIndex} = swiper;
        setActiveSlide(activeIndex);
        swiper.slideTo(activeIndex);
    };

    return (
        <Swiper
            className={classes.slider}
            spaceBetween={0}
            slidesPerView={1}
            navigation={false}
            autoplay={{delay: 4000}}
            pagination={{clickable: false}}
            onSlideChange={handleSlideChange}
        >
            {slides.map((slide, index) => (
                <SwiperSlide
                    key={index}
                    className={classes.slide}
                    style={{backgroundImage: `url(${slide.image})`}}
                >
                    <div className={classes.slideContent}>
                        <Typography className={classes.title}>{slide.title}</Typography>
                        {slide.description ? (
                            <Typography className={classes.description}>{slide.description}</Typography>
                        ) : null}

                        {index === 0 && (
                            <CustomButton width="100%" text="Зателефонувати"/>
                        )}
                    </div>
                </SwiperSlide>
            ))}
            <div className={classes.pagination}>
                {slides.map((_, i) => (
                    <div
                        key={i}
                        className={`${classes.paginationItem} ${
                            i === activeSlide ? classes.paginationItemActive : ""
                        }`}
                    />
                ))}
            </div>
        </Swiper>
    );
};

export default Carousel;



