import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {IconButton, Drawer, Box} from '@material-ui/core';
import ToggleMenuIcon from '../../../assets/icon/toggle-menu-icon.svg';
import './styles.css';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "../../UI/Button";
import ContactButton from "../../UI/ContactButton";
import AdbisBadge from "../../UI/AdbisPromoutedBagde";


const navLinksData = [
    {id: 'about', text: 'О нас'},
    {id: 'catalog', text: 'Проєкти'},
    {id: 'reasons', text: 'Наші послуги'},
    {id: 'contact', text: 'Контакти'},
];

const useStyles = makeStyles((theme) => ({
    menuButton: {
        color: '#1E1E1E',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    list: {
        width: '100%',
        height: '100%',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        //justifyContent: 'space-between',

    },
    drawer: {
        width: '100%',
        height: '100%',
        backgroundColor: '#1E1E1E',
    },
    navBlock: {
        color: '#f2f2f2',
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',

    },
    navigationText: {
        fontFamily: 'Inter-Bold',
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '24px',
        color: '#f2f2f2',
        textTransform: 'none',
        '&:hover': {
            color: '#BDFFA6',
        },
    },
    closeIcon: {
        color: '#f2f2f2',
        fontSize: '55px',
        marginLeft: 'auto',
        '&:hover': {
            color: '#BDFFA6',
        },
    },
    headerBlock: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '20px',
    },
    root: {
        width: '100%',
        backgroundColor: '#1E1E1E',
    },
    title: {
        fontFamily: 'Montserrat-Bold',
        fontWeight: '700',
        lineHeight: '24px',
        fontSize: '20px',
        color: '#f2f2f2',
        marginBottom: '20px',
    },
    text: {
        fontFamily: 'Montserrat',
        fontSize: '14px',
        color: '#f2f2f2',
        fontWeight: '400',
        lineHeight: '17px',
    },
    contactSection: {
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
        marginBottom: '16px',
        textDecoration: 'none',
        color: '#f2f2f2',
    },
    navButton: {
        width: '100%',
        padding: '16px 16px 16px 0',
        justifyContent: 'flex-start',
        borderBottom: '1px solid #F2F2F2',
        borderRadius: '0',
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        marginBottom: '12px',
    },
    description: {
        fontSize: '18px',
        fontFamily: 'Inter-Regular',
        lineHeight: '24px',
        color: '#000',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        }
    },
    contactCard: {
        backgroundColor: '#fff',
        borderRadius: '20px',
        padding: '20px',
    },
    titleCard: {
        fontSize: '28px',
        fontFamily: 'Inter-Bold',
        lineHeight: '24px',
        color: '#262626',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        }
    },
    buttonContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        rowGap: '20px',
        columnGap: '20px',
        justifyItems: 'end',
        [theme.breakpoints.down('sm')]: {
            rowGap: '10px',
            columnGap: '10px',
        }
    },
    boxView: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    }
}));

function ToggleMenu() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        window.scrollTo({
            top: section.offsetTop,
            behavior: 'smooth',
        });
    };

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setOpen(open);
    };

    const list = () => (
        <>
            <div
                className={classes.list}
                role="presentation"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
            >
                <Box className={classes.headerBlock}>
                    <CloseIcon className={classes.closeIcon} onClick={toggleDrawer(false)}/>
                </Box>
                <Box className={classes.boxView}>
                    <Box className={classes.navBlock}>
                        {navLinksData.map((link) => (
                            <Button onClick={() => scrollToSection(link.id)} className={classes.navButton}>
                                <Typography className={classes.navigationText}>
                                    {link.text}
                                </Typography>
                            </Button>
                        ))}
                    </Box>
                    <Box className={classes.contactCard}>
                        <Box className={classes.textContainer}>
                            <Typography className={classes.titleCard}>
                                Зв'яжіться з нами
                            </Typography>
                            <Typography className={classes.description}>
                                Напишіть нам у месенджер або наберіть одразу на мобільний телефон
                            </Typography>
                        </Box>
                        <CustomButton width='100%' text="Зателефонувати"/>
                    </Box>
                    <Box className={classes.buttonContainer}>
                        <ContactButton text="Telegram" to="https://t.me/woodplus15"/>
                        <ContactButton text="Viber" tel="+380970040005"/>
                    </Box>
                </Box>
            </div>
            <AdbisBadge/>
        </>
    );

    return (
        <div>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu"
                        onClick={toggleDrawer(true)}>
                <img src={ToggleMenuIcon} alt="toggle-menu-icon"/>
            </IconButton>
            <Drawer anchor="top" open={open} onClose={toggleDrawer(false)} classes={{paper: classes.drawer}}>
                {list()}
            </Drawer>
        </div>
    );
}

export default ToggleMenu;
