import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import {Box} from "@material-ui/core";
import ToggleMenu from "../ToggleMenu";
import Logo from '../../../assets/icon/logo.svg';

const navLinksData = [
    {id: 'about', text: 'О нас'},
    {id: 'catalog', text: 'Проєкти'},
    {id: 'reasons', text: 'Наші послуги'},
    {id: 'contact', text: 'Контакти'},
];

const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: '#1E1E1E',
        boxShadow: 'none',
        transition: "all 0.3s ease-out",
    },
    // hidden: {
    //     transform: "translate(0, -100%)",
    // },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    toolbar: {
        padding: '10px 30px',
        margin: '20px 40px',
        borderRadius: '12px',
        border: '2px solid #fff',
        [theme.breakpoints.down('sm')]: {
            margin: '20px',
            display: 'flex',
            border: 'none',
            borderRadius: '0',
            padding: '0',
            justifyContent: 'space-between',
        },
        [theme.breakpoints.up('lg')]: {
            margin: '20px 100px'
        },
        [theme.breakpoints.up('xl')]: {
            margin: '20px 150px'
        }
    },
    title: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        textAlign: 'center',

        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    navButton: {
        color: '#F2F2F2',
        fontSize: '20px',
        fontFamily: 'Inter-Regular',
        textTransform: 'none',
        fontWeight: 400,
        lineHeight: '24px',
        fontHeight: '20px',
        padding: '10px',
        '&:hover': {
            backgroundColor: 'transparent',
            color: '#BDFFA6',
        }
    },
    logoBlock: {
        width: '50%',
    },
    logo: {
        height: '50px',
        width: '50px',
    }
}));

function Header() {
    const classes = useStyles();
    //TODO: refactor
    // const [scrolled, setScrolled] = useState(false);
    // const [prevScrollPos, setPrevScrollPos] = useState(0);
    // const [visible, setVisible] = useState(true);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        window.scrollTo({
            top: section.offsetTop,
            behavior: 'smooth',
        });
    };

    // useEffect(() => {
    //     const handleScroll = () => {
    //         const currentScrollPos = window.pageYOffset;
    //         setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
    //         setPrevScrollPos(currentScrollPos);
    //     };
    //
    //     window.addEventListener("scroll", handleScroll);
    //
    //     return () => {
    //         window.removeEventListener("scroll", handleScroll);
    //     };
    // }, [prevScrollPos, visible]);
    //
    //
    // useEffect(() => {
    //     const handleScroll = () => {
    //         if (window.pageYOffset > 0) {
    //             setScrolled(true);
    //         } else {
    //             setScrolled(false);
    //         }
    //     };
    //
    //     window.addEventListener('scroll', handleScroll);
    //
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);

    return (
        <AppBar position="static"
            //className={`${classes.appBar} ${!visible && classes.hidden}`}
                className={classes.appBar}
        >

            <Toolbar className={classes.toolbar}>
                <Box className={classes.logoBlock}>
                    <Typography>
                        <Button onClick={() => scrollToSection('banner')} style={{color: '#fff', fontSize: '20px'}}>
                            <img src={Logo} alt="logo" className={classes.logo}/>
                        </Button>
                    </Typography>
                </Box>
                <Box className={classes.title}>
                    {navLinksData.map((link) => (
                        <Button
                            key={link.id}
                            className={classes.navButton}
                            onClick={() => scrollToSection(link.id)}
                        >
                            {link.text}
                        </Button>
                    ))}
                </Box>
                <Box>
                    <ToggleMenu/>
                </Box>
            </Toolbar>
        </AppBar>
    );
}

export default Header;