import React from "react";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    button: {
        background: 'transparent',
        border: '2px solid #fff',
        borderRadius: '10px',
        width: '100%',
        padding: '63px 0',
        fontFamily: 'Inter-Bold',
        fontSize: '20px',
        lineHeight: '24px',
        color: '#fff',
        textTransform: 'none',
        '&:hover': {
            background: '#BDFFA6',
            borderColor: '#BDFFA6',
            color: '#000'

        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        }
    },
}));

const ContactButton = ({ text, onClick, to, tel, children }) => {
    const classes = useStyles();

    if (tel) {
        return (
            <a href={`tel:${tel}`} style={{ textDecoration: 'none', width: '100%' }}>
                <Button className={classes.button} onClick={onClick}>
                    {children}
                    {text}
                </Button>
            </a>
        );
    }

    return (
        <Link to={to} style={{ textDecoration: 'none', width: '100%' }} target="_blank" rel="noreferrer">
            <Button className={classes.button} onClick={onClick}>
                {children}
                {text}
            </Button>
        </Link>
    );
};

export default ContactButton;
