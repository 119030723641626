import React from "react";
import {Button, useMediaQuery} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    button: {
        padding: '17px',
        borderRadius: ({borderRadius}) => borderRadius || "10px",
        fontWeight: 600,
        width: ({width, isMobile}) => (isMobile ? "50%" : width || "100%"),
        color: '#161616',
        fontFamily: 'Inter-Regular',
        fontSize: '20px',
        backgroundColor: '#BDFFA6',
        textTransform: 'none',
        border: '2px solid #BDFFA6',
        transition: "background-color 0.5s ease",
        "&:hover": {
            backgroundColor: "transparent",
            border: '2px solid #fff',
            color: '#fff',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            padding: '10px',
            width: "50%", // Ширина для мобильного вида
        }
    },
    disabled: {
        opacity: 0.6,
        pointerEvents: "none",
    },
}));

const CustomButton = ({children, disabled, text, onClick, width, borderRadius, ...props}) => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const classes = useStyles({width, isMobile, borderRadius});

    return (
        <a href={`tel:+380970040005`} style={{textDecoration: 'none', width: '100%', display: 'flex', justifyContent: 'center'}}>
            <Button
                className={`${classes.button} ${disabled ? classes.disabled : ""}`}
                onClick={onClick}
                disabled={disabled}
                {...props}
            >
                {text}
                {children}
            </Button>
        </a>
    );
};

export default CustomButton;
