import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import SectionWrapper from '../UI/SectionWrapper';
import Button from '@material-ui/core/Button';
import ArrowIcon from '../../assets/icon/arrow-icon.svg';
import {Box, Typography, Dialog, DialogTitle, DialogContent, DialogActions, IconButton} from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
    title: {
        color: '#f2f2f2',
        fontSize: '60px',
        fontFamily: 'Inter-Bold',
        textAlign: 'center',
        marginBottom: '38px',
        fontWeight: 700,
        width: '50%',
        margin: "0 auto",
        [theme.breakpoints.down('sm')]: {
            fontSize: '30px',
            width: '100%',
        }
    },
    tabsContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "44px",
    },
    tabsButtonLeft: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "12px 0 0 12px",
        border: 'none',
        width: '25%',
        fontSize: '20px',
        fontFamily: 'Inter-Regular',
        backgroundColor: "#F2F2F2",
        padding: "15px",
        textTransform: "none",
        color: 'rgba(0, 0, 0, 0.5)',
        '&:hover': {
            backgroundColor: "#BDFFA6",
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
    },
    tabsButtonRight: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "0 12px 12px 0",
        border: 'none',
        width: '25%',
        fontSize: '20px',
        textTransform: "none",
        fontFamily: 'Inter-Regular',
        backgroundColor: "#F2F2F2",
        padding: "15px",
        color: 'rgba(0, 0, 0, 0.5)',
        '&:hover': {
            backgroundColor: "#BDFFA6",
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
    },
    tabsButtonActive: {
        backgroundColor: "#BDFFA6",
    },
    imagesContainer: {
        display: "grid",
        animation: `$fade-in 0.5s ease`,
        gridTemplateColumns: "repeat(3, 1fr)",
        gap: "10px",
        justifyContent: "center",
        margin: "0 auto",
        maxWidth: "1200px",
        "& img": {
            width: "100%",
            height: "auto",
            borderRadius: "20px",
            objectFit: "cover",
            aspectRatio: "1 / 1",
        },
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: "repeat(2, 1fr)",
        }
    },
    image: {
        width: "100%",
        height: "auto",
        cursor: "pointer",
        borderRadius: "20px",
        cover: "no-repeat",
    },
    '@keyframes fade-in': {
        '0%': {
            opacity: 0,
        },
        '100%': {
            opacity: 1,
        },
    },// ... (ваш текущий стиль)
    viewAllButton: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: '#ffffff',
        width: '100%',
        height: '100%',
        borderRadius: '20px',
        position: 'absolute',
        top: '50%',
        fontFamily: 'Inter-Regular',
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '24px',
        textTransform: 'none',
        letterSpacing: '0em',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
        padding: '10px 20px',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        }
    },
    dialog: {
        '& .MuiDialogTitle-root': {
            padding: '0',
        },
        '& .MuiDialogContent-root': {
            padding: '40px 0 90px 0',
            [theme.breakpoints.down('sm')]: {
                padding: '0 0 40px 0',
            }
        },
        '& .MuiDialog-paper': {
            borderRadius: '20px',
        }
    },
    dialogImage: {
        width: '100vh',
        height: '70vh',
        borderRadius: '20px',
        objectFit: 'cover',
        aspectRatio: '1 / 1',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: 'auto',
        }
    },
    dialogImageContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
    },
    dialogContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    arrowLeft: {
        transform: 'rotate(90deg)',
    },
    arrowRight: {
        transform: 'rotate(-90deg)',
    }
}));


const Catalog = () => {
    const classes = useStyles();
    const [viewAllDialogOpen, setViewAllDialogOpen] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [currentTab, setCurrentTab] = useState('Дерево');
    const [woodImages, setWoodImages] = useState([]);
    const [metalImages, setMetalImages] = useState([]);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const categoryResponse = await axios.get(`https://woodplus.com.ua/api/wp-json/wp/v2/categories?slug=wood`);
                const categoryId = categoryResponse.data[0]?.id;

                if (!categoryId) {
                    console.error('Category "wood" not found.');
                    return;
                }

                const response = await axios.get(`https://woodplus.com.ua/api/wp-json/wp/v2/posts?categories=${categoryId}&_embed`);

                const woodImages = response.data.map(post => {
                    return post._embedded?.['wp:featuredmedia']?.[0]?.source_url || '';
                });

                setWoodImages(woodImages);

                const categoryResponseMetal = await axios.get(`https://woodplus.com.ua/api/wp-json/wp/v2/categories?slug=metal`);
                const categoryIdMetal = categoryResponseMetal.data[0]?.id;

                if (!categoryIdMetal) {
                    console.error('Category "metal" not found.');
                    return;
                }

                const responseMetal = await axios.get(`https://woodplus.com.ua/api/wp-json/wp/v2/posts?categories=${categoryIdMetal}&_embed`);

                const metalImages = responseMetal.data.map(post => {
                    return post._embedded?.['wp:featuredmedia']?.[0]?.source_url || '';
                });

                setMetalImages(metalImages);
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };

        fetchImages().then(r => console.log('Images fetched'));
    }, []); // Обновление изображений при загрузке страницы


    const handleTabChange = (tab) => {
        setCurrentTab(tab);
        setSelectedImageIndex(0);
    };

    const handleViewAllClick = () => {
        setViewAllDialogOpen(true);
    };

    const handleViewAllDialogClose = () => {
        setViewAllDialogOpen(false);
    };

    const handlePreviousImage = () => {
        setSelectedImageIndex((prevIndex) => Math.max(0, prevIndex - 1));
    };

    const handleNextImage = () => {
        const images = currentTab === 'Дерево' ? woodImages : metalImages;
        setSelectedImageIndex((prevIndex) => Math.min(images.length - 1, prevIndex + 1));
    };

    const images = currentTab === 'Дерево' ? woodImages : metalImages;

    return (
        <SectionWrapper id="catalog">
            <Box>
                <Typography className={classes.title}>
                    Що ми робимо та з чим працюємо
                </Typography>
            </Box>
            <Box className={classes.tabsContainer}>
                <Button
                    className={`${classes.tabsButtonLeft} ${
                        currentTab === 'Дерево' ? classes.tabsButtonActive : ''
                    }`}
                    onClick={() => handleTabChange('Дерево')}
                >
                    Дерево
                </Button>
                <Button
                    className={`${classes.tabsButtonRight} ${
                        currentTab === 'Металл' ? classes.tabsButtonActive : ''
                    }`}
                    onClick={() => handleTabChange('Металл')}
                >
                    Металл
                </Button>
            </Box>
            <div className={classes.imagesContainer}>
                {images.slice(0, 6).map((url, index) => (
                    <div key={index} style={{ position: 'relative' }}>
                        <img
                            src={url}
                            alt={`${currentTab} ${index}`}
                            className={classes.image}
                            loading="lazy"
                            onClick={() => {
                                setSelectedImageIndex(index);
                                setViewAllDialogOpen(true);
                            }}
                        />
                        {index === 5 && (
                            <Button className={classes.viewAllButton} onClick={handleViewAllClick}>
                                + {images.length} Фотографій
                            </Button>
                        )}
                    </div>
                ))}

            </div>

            <Dialog
                open={viewAllDialogOpen}
                onClose={handleViewAllDialogClose}
                maxWidth="lg"
                fullWidth
                className={classes.dialog}
            >
                <DialogTitle style={{textAlign: 'right'}}>
                    <IconButton color="inherit" onClick={handleViewAllDialogClose}>
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <div style={{display: 'flex', alignItems: 'center', gap: '2%'}}>
                        <IconButton
                            onClick={handlePreviousImage}
                            disabled={selectedImageIndex === 0}
                            color="primary"
                        >
                            <img src={ArrowIcon} alt="arrow" className={classes.arrowLeft}/>
                        </IconButton>
                        <div className={classes.dialogImageContainer}>
                            <img
                                src={images[selectedImageIndex]}
                                alt={`${currentTab} ${selectedImageIndex}`}
                                className={classes.dialogImage}
                            />
                        </div>
                        <IconButton
                            onClick={handleNextImage}
                            disabled={selectedImageIndex === images.length - 1}
                            color="primary"
                        >
                            <img src={ArrowIcon} alt="arrow" className={classes.arrowRight}/>
                        </IconButton>
                    </div>
                </DialogContent>
            </Dialog>
        </SectionWrapper>
    );
};

export default Catalog;
