import React, {Component} from 'react';
import Header from "./components/Layout/Header";
import Footer from "./components/Layout/Footer";
import {CssBaseline} from "@material-ui/core";
import Banner from "./components/Banner";
import About from "./components/About";
import Reasons from "./components/Reasons";
import Projects from "./components/Projects";
import Catalog from "./components/Catalog";
//import Services from "./components/Services";

class App extends Component {
    authenticate(){
        return new Promise(resolve => setTimeout(resolve, 300))
    }

    componentDidMount(){
        this.authenticate().then(() => {
            const ele = document.getElementById('ipl-progress-indicator')
            if(ele){
                ele.classList.add('available')
                setTimeout(() => {
                    ele.outerHTML = ''
                }, 300)
            }
        })
    }
    render() {
        return (
            <>
                <CssBaseline />
                <Header />
                <Banner />
                <About />
                <Catalog />
                <Projects />
                <Reasons />
                <Footer/>
            </>
        );
    };
}

export default App;
