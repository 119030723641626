import React, {useState, useEffect, useRef} from 'react';
import SectionWrapper from "../UI/SectionWrapper";
import {useTheme} from "@material-ui/core/styles";
import {Box, Typography, useMediaQuery} from "@material-ui/core";
import WoodIcon from '../../assets/icon/wood-icon.svg';
import MetalIcon from '../../assets/icon/metal-icon.svg';
import ArrowIcon from "../../assets/icon/arrow-icon.svg";
import CustomButton from "../UI/Button";
import useStyles from "./styles";
import axios from "axios";

const Projects = () => {
    const classes = useStyles();
    const [projectsData, setProjectsData] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [expandedStates, setExpandedStates] = useState([]);  // Массив состояний для каждой карточки
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const timeoutIdRef = useRef(null);

    const handleArrowClick = (index) => {
        setExpandedStates((prevStates) => {
            const newStates = [...prevStates];
            newStates[index] = !newStates[index];
            return newStates;
        });
    };

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const categoryResponse = await axios.get(`https://woodplus.com.ua/api/wp-json/wp/v2/categories?slug=projects`);
                const categoryId = categoryResponse.data[0]?.id;

                if (!categoryId) {
                    console.error('Category "projects" not found.');
                    return;
                }

                const response = await axios.get(`https://woodplus.com.ua/api/wp-json/wp/v2/posts?categories=${categoryId}&_embed`);

                const projectsData = response.data.map(project => {
                    const title = project.title.rendered || 'No Title';
                    const description = project.content?.rendered || 'No Description';
                    const imageUrl = project._embedded?.['wp:featuredmedia']?.[0]?.source_url || '';

                    return {
                        title,
                        description,
                        bannerImage: imageUrl,
                    };
                });

                // Инициализация состояний - все закрыты
                setExpandedStates(new Array(projectsData.length).fill(false));
                setProjectsData(projectsData);
            } catch (error) {
                console.error('Error fetching project data from WordPress API:', error);
            }
        };

        fetchProjects().then(() => console.log('Projects data fetched'));
    }, []);

    const updateIndex = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % projectsData.length);
        timeoutIdRef.current = setTimeout(updateIndex, 5000);
    };

    useEffect(() => {
        timeoutIdRef.current = setTimeout(updateIndex, 5000);

        return () => {
            if (timeoutIdRef.current) {
                clearTimeout(timeoutIdRef.current);
            }
        };
    }, [projectsData]);

    return (
        <SectionWrapper id="projects">
            <Box>
                <Typography className={classes.title}>Наші проєкти</Typography>
            </Box>
            {projectsData.slice(currentIndex, currentIndex + 2).map((project, index) => (
                <Box key={index} className={`${classes.boxView} ${expandedStates[index] ? 'expanded' : ''}`}>
                    <Box className={`${classes.infoCard} ${expandedStates[index] ? 'expanded' : ''}`}>
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography className={classes.subtitle} dangerouslySetInnerHTML={{ __html: project.title }} />
                            {isMobile && (
                                <img
                                    src={ArrowIcon}
                                    alt="Expand"
                                    className={`${classes.arrowIcon} ${expandedStates[index] ? classes.expanded : ''}`}
                                    onClick={() => handleArrowClick(index)}
                                />
                            )}
                        </Box>
                        {(expandedStates[index] || !isMobile) && (
                            <>
                                <Typography className={classes.description} dangerouslySetInnerHTML={{ __html: project.description }} />
                                <Typography className={classes.subtitle}>Що використовували</Typography>
                                <Box>
                                    <Box style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                        <img src={MetalIcon} alt="metal-icon" className={classes.icon} />
                                        <img src={WoodIcon} alt="wood-icon" className={classes.icon} />
                                    </Box>
                                </Box>
                            </>
                        )}
                    </Box>
                    <Box className={classes.imgCard} style={{ backgroundImage: `url(${project.bannerImage})` }}>
                        <CustomButton text="Зателефонувати" width="80%" mobileWidth="50%" />
                    </Box>
                </Box>
            ))}
        </SectionWrapper>
    );
};

export default Projects;